/* app/src/components/MaintenanceMessage.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageBox {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.messageBox h1 {
  margin-bottom: 1rem;
  color: #333333;
}

.messageBox p {
  color: #666666;
}
